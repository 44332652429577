document.onreadystatechange = function () {

    if (document.readyState === 'complete') {
        getProjectData();
    }

    addFeedbackEssentials();
}

let selectedRating = null;
let functionality_question = null;
let communication_question = null;
let agreements_question = null;
let explanation_ratings = null;
let giver = null;
let comment = null;
let extra_questions = null;
let milestone = null;
let extraQuestionNumber = 1;
const projectHostname = document.location.hostname;
const requestUrl = process.env.MIX_APP_FEEDBACK_URL;
const urlToExclamationIcon = process.env.MIX_APP_ICON_URL_PATH;

function submitRating() {
    fetch(requestUrl, {
        method: 'POST',
        body: JSON.stringify({
            project_hostname: projectHostname,
            rating: selectedRating,
            name: giver,
            comments: comment,
            extra_questions: extra_questions,
            functionality_question: functionality_question,
            agreements_question: agreements_question,
            communication_question: communication_question,
            explanation_ratings: explanation_ratings,
        }),
    })
        .then(() => setTimeout(() => {
            window.location.reload()
        }, 500))
        .catch(err => alert(JSON.stringify(err)));
}

function getProjectData() {
    fetch(requestUrl + '/' + projectHostname, {
        type: 'GET',
    })
        .then(response => response.json())
        .then(response => {
            if (response.has_voted === 0 || response.type_feedback === 'Feedback mist') {
                milestone = response.milestone;
                if (response.extra_questions !== undefined) {
                    extra_questions = response.extra_questions;
                }
                loadFeedback(response.type_feedback);
            }
        })
        .catch(err => {
            createFeedbackBalk();
            console.error('error loading feedback');
        })
}

function loadFeedback(typeOfFeedback) {
    switch (typeOfFeedback) {
        case 'balk':
            createFeedbackBalk();
            break;
        case 'FAB':
            createFeedbackButton();
            break;
        case 'none':
            break;
        default:
            createFeedbackBalk();
            break;
    }
}

function toggle(element) {
    element.style.display = !element.style.display || element.style.display === 'none' ? 'block' : 'none'
}

function createFeedbackBalk() {
    let wrapper = createElement('div', 'wrapper');

    createFeedbackElements(wrapper);

    document.body.prepend(wrapper);

    let dropdownFeedback = document.getElementById('dropDownFeedback')

    dropdownFeedback.addEventListener('click', function (e) {
        e.stopPropagation()
    })
}


function createFeedbackButton() {
    let wrapper = createElement('div', 'wrapperFAB')

    createFeedbackElements(wrapper);

    document.body.prepend(wrapper);
}

function createFeedbackElements(wrapper) {
    //create the title and the dropdown for feedback
    const isWrapper = wrapper.id === 'wrapper';

    let openModalEl = createElement('div', 'openModalEl');
    let askFeedback = createElement('div', isWrapper ? 'askFeedback' : 'askFeedbackFAB');
    let logoFeedbackQlic = createElement('img', isWrapper ? 'logoQlicFeedback' : 'exclamationIcon');
    let dropdownFeedback = createElement('div', isWrapper ? 'dropDownFeedback' : 'dropDownFeedbackFAB');
    let askFeedbackTitle = createElement('div', isWrapper ? 'askFeedbackTitle' : 'askFeedbackTitleFAB');
    let closingButtonFeedback = createElement('button', 'closeFeedback');

    let feedbackWrapperElements = createFeedbackWrapperElements();
    let basicFeedbackWrapper = createElement('div', 'basicFeedbackWrapper')
    let feedbackWrapperExtraElements = createElement('div', 'feedbackWrapperExtraElements');
    let verticalStripe = createElement('div', 'verticalStripeFeedback');
    let feedbackFormElements = createFeedbackFormElements();

    let clicker = isWrapper ? askFeedbackTitle : openModalEl

    clicker.addEventListener('click', function () {
        toggle(dropdownFeedback)
        askFeedback.classList.add("modal-bg")
    })

    closingButtonFeedback.addEventListener('click', () => {
        toggle(dropdownFeedback);
        askFeedback.classList.remove("modal-bg")
    })

    //add text to necessary divs
    askFeedbackTitle.innerText = 'Geef Qlic feedback!';
    closingButtonFeedback.innerText = 'X';
    logoFeedbackQlic.src = isWrapper ? 'https://www.qlic.nl/assets/img/logo.png' : urlToExclamationIcon;
    addTextToForm(feedbackFormElements);

    //add basic elements to parent
    feedbackFormElements['milestoneLabel'].appendChild(closingButtonFeedback)
    addFBBtoDiv(5, feedbackWrapperElements['feedbackRating'], 'Rating');
    addFBBtoDiv(5, feedbackWrapperElements['feedbackFunctionality'], 'Functionality');
    addFBBtoDiv(5, feedbackWrapperElements['feedbackCommunication'], 'Communication');
    addFBBtoDiv(5, feedbackWrapperElements['feedbackAgreements'], 'Agreements');

    openModalEl.appendChild(askFeedbackTitle)
    openModalEl.appendChild(logoFeedbackQlic)
    askFeedback.appendChild(openModalEl);
    dropdownFeedback.appendChild(feedbackFormElements['milestoneLabel'])
    addExtraQuestionsToForm(extra_questions, feedbackWrapperElements, feedbackWrapperExtraElements);
    appendChildrenToRightParent(feedbackWrapperElements, feedbackFormElements, basicFeedbackWrapper, verticalStripe);

    //add form to the dropdown, then dropdown to parent
    dropdownFeedback.appendChild(feedbackFormElements['feedbackForm']);
    askFeedback.appendChild(dropdownFeedback);

    wrapper.appendChild(askFeedback);
}

function createRatingButton(int, ratingType) {
    if (Number.isInteger(int)) {
        int++;
        let str = int.toString();
        let ratingButton = document.createElement('button');
        ratingButton.innerHTML = str;
        ratingButton.type = 'button';
        ratingButton.id = `feedback${ratingType}Button` + str;
        ratingButton.className = `feedback${ratingType}Button`;
        ratingButton.onclick = function () {
            addRatingToCorrectVariable(str, ratingType)
            const buttons = document.getElementsByClassName(`feedback${ratingType}Button`);

            for (let i = 0; i < buttons.length; i++) {
                let el = buttons[i]
                el.classList.remove(`selected${ratingType}Button`)
            }

            ratingButton.classList.add(`selected${ratingType}Button`)
        };

        return ratingButton;
    }
}

function addRatingToCorrectVariable(str, ratingType) {
    switch (ratingType) {
        case 'Rating':
            selectedRating = str;
            break;
        case 'Functionality':
            functionality_question = str;
            break;
        case 'Communication':
            communication_question = str;
            break;
        case 'Agreements':
            agreements_question = str;
            break;
        default:
            console.error('rating went wrong');
    }
}

function addExtraRatingButtonsToWrapper(object, amount, feedbackWrapper) {
    for (let i = 0; i < amount; i++) {
        feedbackWrapper.appendChild(createRatingButtonExtraQuestions(object, i));
    }
}

function createRatingButtonExtraQuestions(object, int) {
    if (Number.isInteger(int)) {
        int++;
        let ratingButton = document.createElement('button');
        ratingButton.innerHTML = int.toString();
        ratingButton.type = 'button';
        ratingButton.id = `ratingButtonExtraQuestion${object.id}`;
        ratingButton.className = `ratingButtonExtraQuestion${object.id} feedbackRatingExtraButton`;
        ratingButton.name = `ratingButtonExtraQuestion${object.id}`;
        ratingButton.value = int;
        ratingButton.addEventListener('click', function () {
            const buttons = document.getElementsByClassName(`ratingButtonExtraQuestion${object.id}`);

            for (let i = 0; i < buttons.length; i++) {
                let el = buttons[i]
                el.classList.remove('selectedRatingExtraButton')
            }

            ratingButton.classList.add('selectedRatingExtraButton')
        });

        return ratingButton;
    }
}

function createElement(element, id) {
    if (typeof id == 'string' && typeof element == 'string') {
        let div = document.createElement(element);
        div.id = id;
        return div;
    }
    return document.createElement('div');
}

function addFBBtoDiv(amount, feedbackWrapper, ratingType) {
    for (let i = 0; i < amount; i++) {
        feedbackWrapper.appendChild(createRatingButton(i, ratingType));
    }
}

function addExtraQuestionsToForm(extraQuestions, feedbackWrapperElements, feedbackWrapperExtraElements) {
    if (extraQuestions === null) {
        return;
    }
    extraQuestions.forEach(function (object, keyNumber) {
        let extraQuestionLabel = createElement('div', 'extraQuestionLabel');

        !object.answer_is_required ?
            extraQuestionLabel.innerText = object.question :
            extraQuestionLabel.innerText = object.question + ' *';

        let extraQuestion = addSpecificTypeExtraQuestion(object);

        feedbackWrapperExtraElements.appendChild(extraQuestionLabel);
        feedbackWrapperExtraElements.appendChild(extraQuestion);
        feedbackWrapperElements['feedbackExtra'].appendChild(feedbackWrapperExtraElements);
    })
}

function addSpecificTypeExtraQuestion(object) {
    let extraQuestion;
    let type = object.type_question;

    // 3 is type rating question
    if (type === 3) {
        extraQuestion = createRatingField(object);
    }
    // 2 is type closed question
    else if (type === 2) {
        extraQuestion = createElement('fieldset', 'type_feedback');
        extraQuestion.className = 'type_feedback';
        let yesAnswer = createRadioInput('ja', object);
        let noAnswer = createRadioInput('nee', object);

        let yesAnswerWrapper = createElement('div');
        yesAnswerWrapper.innerText = ' Ja';
        yesAnswerWrapper.className = 'radioButtonWrapper'
        let noAnswerWrapper = createElement('div');
        noAnswerWrapper.innerText = ' Nee';
        noAnswerWrapper.className = 'radioButtonWrapper';

        addElementsToNecessaryParentRadioField(extraQuestion, yesAnswerWrapper, noAnswerWrapper, yesAnswer, noAnswer);
    }
    // (1) is type open question
    else {
        extraQuestion = createElement('textarea', 'extraQuestion');
        extraQuestion.name = `extraQuestionTextArea[${object.id}]`
    }
    return extraQuestion;
}

function createRatingField(object) {
    let ratingWrapper = createElement('div', `extraQuestion${object.id}`);
    ratingWrapper.className = 'ratingWrapper';

    addExtraRatingButtonsToWrapper(object, 5, ratingWrapper);
    return ratingWrapper;
}

function addElementsToNecessaryParentRadioField(extraQuestionField, yesWrapper, noWrapper, yesAnswer, noAnswer) {
    yesWrapper.prepend(yesAnswer);
    noWrapper.prepend(noAnswer);
    extraQuestionField.appendChild(yesWrapper);
    extraQuestionField.appendChild(noWrapper);
}

function createRadioInput(value, object) {
    let createdElement = document.createElement('input');
    createdElement.name = `extraQuestion[${object.id}]`;
    createdElement.type = 'radio';
    createdElement.value = value;

    return createdElement
}

function addTextToForm(feedbackFormElements) {
    feedbackFormElements['ratingLabelFB'].innerText = 'Welk cijfer geeft u deze release? *';
    feedbackFormElements['nameLabelFB'].innerText = 'Voor- en achternaam *';
    feedbackFormElements['commentLabelFB'].innerText = 'Algemene opmerkingen';
    feedbackFormElements['milestoneLabel'].innerText = milestone;

    feedbackFormElements['feedbackFunctionalityLabel'].innerText = 'In welke mate voldoen de functionaliteiten aan de verwachtingen? *';
    feedbackFormElements['feedbackCommunicationLabel'].innerText = 'Welk cijfer geeft u de communicatie tijdens de sprint? *';
    feedbackFormElements['feedbackAgreementsLabel'].innerText = 'Welk cijfer geeft u het nakomen van afspraken? *';
    feedbackFormElements['feedbackRatingExplanationLabel'].innerText = 'Toelichting cijfers';
}

function createFeedbackWrapperElements() {
    return {
        'feedbackGiver': createElement('div', 'feedbackGiver'),
        'feedbackComment': createElement('div', 'feedbackComment'),
        'formWrapper': createElement('div', 'formWrapper'),
        'submitFeedback': createElement('div', 'submitFeedback'),
        'feedbackRating': createElement('div', 'feedbackRating'),
        'feedbackExtra': createElement('div', 'feedbackExtra'),

        'feedbackFunctionality': createElement('div', 'feedbackFunctionality'),
        'feedbackCommunication': createElement('div', 'feedbackCommunication'),
        'feedbackAgreements': createElement('div', 'feedbackAgreements'),
        'feedbackRatingExplanation': createElement('div', 'feedbackRatingExplanation'),
    };
}

function createFeedbackFormElements() {
    return {
        //basic form elements
        'feedbackForm': createElement('form', 'feedbackForm'),
        'nameFeedbackGiver': createElement('input', 'nameFeedbackGiver'),
        'feedbackComments': createElement('textarea', 'feedbackComments'),
        'ratingLabelFB': createElement('div', 'ratingLabelFB'),
        'milestoneLabel': createElement('div', 'milestoneLabel'),
        'nameLabelFB': createElement('div', 'nameLabelFB'),
        'commentLabelFB': createElement('div', 'commentLabelFB'),
        'feedbackSubmitButton': createFeedbackSubmit(),

        //labels for extra standard questions
        'feedbackFunctionalityLabel': createElement('div', 'feedbackFunctionalityLabel'),
        'feedbackCommunicationLabel': createElement('div', 'feedbackCommunicationLabel'),
        'feedbackAgreementsLabel': createElement('div', 'feedbackAgreementsLabel'),
        'feedbackRatingExplanationLabel': createElement('div', 'feedbackRatingExplanationLabel'),
        'feedbackRatingExplanationTextArea': createElement('textarea', 'feedbackRatingExplanationTextArea'),
    };
}

function appendChildrenToRightParent(feedbackWrapperElements, feedbackFormElements, basicFeedbackWrapper, verticalStripe) {

    feedbackWrapperElements['feedbackGiver'].appendChild(feedbackFormElements['nameFeedbackGiver']);
    feedbackWrapperElements['feedbackRatingExplanation'].appendChild(feedbackFormElements['feedbackRatingExplanationLabel']);
    feedbackWrapperElements['feedbackComment'].appendChild(feedbackFormElements['nameLabelFB']);
    feedbackWrapperElements['submitFeedback'].appendChild(feedbackFormElements['feedbackSubmitButton']);

    //add ellements to form wrapper
    basicFeedbackWrapper.appendChild(feedbackFormElements['nameLabelFB']);
    basicFeedbackWrapper.appendChild(feedbackWrapperElements['feedbackGiver']);
    basicFeedbackWrapper.appendChild(feedbackFormElements['ratingLabelFB']);
    basicFeedbackWrapper.appendChild(feedbackWrapperElements['feedbackRating']);

    basicFeedbackWrapper.appendChild(feedbackFormElements['feedbackFunctionalityLabel']);
    basicFeedbackWrapper.appendChild(feedbackWrapperElements['feedbackFunctionality']);
    basicFeedbackWrapper.appendChild(feedbackFormElements['feedbackCommunicationLabel']);
    basicFeedbackWrapper.appendChild(feedbackWrapperElements['feedbackCommunication']);
    basicFeedbackWrapper.appendChild(feedbackFormElements['feedbackAgreementsLabel']);
    basicFeedbackWrapper.appendChild(feedbackWrapperElements['feedbackAgreements']);

    //comments
    basicFeedbackWrapper.appendChild(feedbackFormElements['feedbackRatingExplanationLabel']);
    basicFeedbackWrapper.appendChild(feedbackFormElements['feedbackRatingExplanationTextArea']);
    basicFeedbackWrapper.appendChild(feedbackFormElements['commentLabelFB']);
    basicFeedbackWrapper.appendChild(feedbackFormElements['feedbackComments']);
    feedbackWrapperElements['formWrapper'].appendChild(basicFeedbackWrapper);
    feedbackWrapperElements['formWrapper'].appendChild(verticalStripe);
    feedbackWrapperElements['formWrapper'].appendChild(feedbackWrapperElements['feedbackExtra']);

    //add wrapper to form
    feedbackFormElements['feedbackForm'].appendChild(feedbackWrapperElements['formWrapper']);
    feedbackFormElements['feedbackForm'].appendChild(feedbackWrapperElements['submitFeedback']);
}

function createFeedbackSubmit() {
    //send button feedback
    let btn = document.createElement('button');
    btn.innerHTML = 'Verstuur feedback!';
    btn.type = 'submit';
    btn.id = 'feedbackSubmitButton';
    btn.onclick = function () {
        giver = document.getElementById('nameFeedbackGiver').value;
        comment = document.getElementById('feedbackComments').value;
        explanation_ratings = document.getElementById('feedbackRatingExplanationTextArea').value;

        extra_questions.forEach(function (object) {
            let htmlObject = document.querySelector(`input[type='radio'][
                name='extraQuestion[${object.id}]']:checked`);
            if (!htmlObject) {
                htmlObject = document.getElementsByName(`extraQuestionTextArea[${object.id}]`)[0];
            }
            if (!htmlObject) {
                htmlObject = document.querySelector(`button[type='button'][
                class='ratingButtonExtraQuestion${object.id} feedbackRatingExtraButton selectedRatingExtraButton']`);
            }
            if (htmlObject) {
                object.answer = htmlObject.value;
            }
        });

        if (selectedRating !== null && selectedRating !== '') {
            if (giver !== null && giver !== '' && checkIfMandatoryQuestionsAreFilled(extra_questions)) {
                if (functionality_question !== null && communication_question !== null && agreements_question !== null) {
                    submitRating();
                    return false;
                }
            }
        }

        displayAlertBox()
        const alertBox = document.getElementById('customAlertBoxFeedback')
        fadeAlertBoxFbIn(alertBox)
            .then(() => fadeAlertBoxFbOut(alertBox))
            .then(() => setTimeout(() => {alertBox.remove()}, 700));
        return false;
    }
    return btn;
}

function checkIfMandatoryQuestionsAreFilled(extraQuestions) {
    for (const element of extraQuestions) {
        if (!!element['answer_is_required'] && element['answer'] === null ||
            !!element['answer_is_required'] && element['answer'] === '') {
            return false;
        }
    }
    return true;
}

function addFeedbackEssentials() {
    let fontLink = document.createElement('link');
    fontLink.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap';
    fontLink.rel = 'stylesheet';

    let styleSheet = document.createElement('style');
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
}

function displayAlertBox() {
    let alertBox = document.createElement('div');
    let alertText = document.createElement('div');

    alertBox.className = 'customAlertBoxFeedback';
    alertBox.id = 'customAlertBoxFeedback';

    alertText.innerText = 'Vul alstublieft de verplichte vragen in!';
    alertText.className = 'customAlertMessageFeedback'

    alertBox.appendChild(alertText);

    document.body.prepend(alertBox);
}

function fadeAlertBoxFbOut(alertBox) {
    return new Promise((resolve,reject)=> {
        setTimeout(() => {
            alertBox.style.opacity = 0
            resolve()
        }, 2000)
    })
}

function fadeAlertBoxFbIn(alertBox) {
    return new Promise((resolve,reject)=> {
        setTimeout(() => {
            alertBox.style.opacity = 1
            resolve()
        }, 200)
    })
}

const styles = `
    #wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: 1;
        width: 100%;
        height: 85px;
        font-size: large !important;
        background-color: white;
        font-family: 'Inter', sans-serif;
        box-shadow: 0px 0px 8px -5px #000000;
        line-height: 1rem !important;
    }
    #wrapperFAB{
        position: fixed;
        color: #2a3b4c;
        background: white;
        right: 50px;
        bottom: 50px;
        z-index: 9000;
        border-radius: 5px;
        font-family: 'Inter', sans-serif;
        box-shadow: 0px 0px 5px -2px #000000;
        line-height: 1rem !important;
    }
    #askFeedbackFAB{
        display: flex;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        z-index: 9999;
        position: relative;
    }

    #openModalEl {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
    }

    #dropDownFeedbackFAB{
        display: none;
        position: fixed;
        width: 48rem;
        left: 10%;
        right: 10%;
        margin: 0 auto;
        top: 5%;
        z-index: 99999;
        background: white;
        box-shadow: 0px 0px 8px -5px #000000;
        z-index: 9998;
        border-radius: 10px;
        padding-left: 40px;
        padding-bottom: 30px;
        padding-top: 30px;
        padding-right: 40px;
        height: 800px;
    }

    #exclamationIcon{
        padding-left: 5px;
        width: 30px;
        filter: invert(52%) sepia(67%) saturate(4086%) hue-rotate(142deg) brightness(97%) contrast(101%);
    }

    #basicFeedbackWrapper {
        position: sticky;
        width: 50%;
    }

     #askFeedbackTitleFAB {
        text-align: center;
        font-weight: bolder;
    }

    #askFeedbackTitleFAB{
        cursor: pointer;
    }

    #askFeedback{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        z-index: 9999;
        position: relative;
    }

    #milestoneLabel {
        font-weight: 700;
        font-size: 1.10rem !important;
        margin-bottom: 20px;
    }

    #logoQlicFeedback {
        width: 150px;
        height: auto;
        padding-right: 50px;
    }

    #askFeedbackTitle:hover{
        cursor: pointer;
        background: #00A18D;
    }

    #askFeedbackTitle {
      cursor: pointer;
      font-size: 1.1rem !important;
      font-weight: bold;
      color: #2a3b4c;
      margin-left: 50px;
      padding: 15px 20px;
      border-radius: 5px;
      background: #00B09A;
      color: white;
    }

    #dropDownFeedback {
        display: none;
        position: absolute;
        width: 48rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 100px;
        z-index: 99999;
        background: white;
        box-shadow: 0px 0px 8px -5px #000000;
        z-index: 9998;
        border-radius: 10px;
        padding-left: 40px;
        padding-bottom: 30px;
        padding-top: 30px;
        padding-right: 40px;
        height: 800px;
    }
    #feedbackForm{
        height: 100%;
        width: 100%;
        border-radius: 5px;
        display: inline-block;
    }
    #submitFeedback{
        height: 15%;
        padding-top: 15px;
        margin-top: 15px;
    }
    #formWrapper{
        height: 85%;
        font-size: 0.95rem !important;
        color: #2a3b4c;
        font-weight: 500;
        display: flex;
        gap: 25px;
        margin: 0 auto;
    }

    #verticalStripeFeedback{
        height: 100%;
        width: 1px;
        border: 1px solid #d8d8d8;
        opacity: .3;
    }

    #feedbackSubmitButton{
        background: #00B09A;
        border-radius: 5px;
        color: white !important;
        font-family: inherit !important;
        font-weight: 400 !important;
        transition: all .2s ease-out !important;
        padding: 10px 20px !important;
        outline: none !important;
        border: none !important;
    }
    #feedbackSubmitButton:hover{
        background: #039685;
        transition: all 0.2s;
        cursor:pointer;
    }
    #feedbackSubmitButton:active{
        transform: translateY(3px);
        transition: transform .2s;
    }
    #feedbackRating,
    .ratingWrapper{
        height: 30px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
    }
    .feedbackRatingButton,
    .feedbackFunctionalityButton,
    .feedbackCommunicationButton,
    .feedbackAgreementsButton,
    .feedbackRatingExtraButton{
        height: 30px;
        width: 30px;
        background-color: #00B09A;
        border-radius: 5px;
        margin-left: 2px;
        margin-right: 2px;
        color:white;
        transition: .2s ease-out;
        outline: none !important;
        border: none !important;
        justify-content: center !important;
        align-items: center !important;
        font-size:
        font-weight: bold !important;
    }
    .feedbackRatingButton:hover,
    .feedbackFunctionalityButton:hover,
    .feedbackCommunicationButton:hover,
    .feedbackAgreementsButton:hover,
    .feedbackRatingExtraButton:hover{
        background-color: #039685;
        transition: .5s;
        cursor: pointer;
    }
    .feedbackRatingButton:focus,
    .feedbackRatingExtraButton:focus{
        background-color: #2a3b4c;
    }
    .selectedRatingButton,
    .selectedFunctionalityButton,
    .selectedCommunicationButton,
    .selectedAgreementsButton,
    .selectedRatingExtraButton{
        background-color: #2a3b4c;
    }

    #ratingLabelFB {
        margin-top: 12px;
    }

    #ratingLabelFB,
    #nameLabelFB,
    #commentLabelFB,
    #feedbackRatingExplanationLabel,
    #feedbackFunctionalityLabel,
    #feedbackCommunicationLabel,
    #feedbackAgreementsLabel,
    #extraQuestionLabel
    {
        margin-bottom: 7px;
        margin-top: 12px;
        margin-left: 2px;
    }
    #extraQuestionLabel{
        width: 100%;
        word-break: break-word;
    }
    #feedbackGiver{
        height: 25px;

    }
    #nameFeedbackGiver{
        height: 100%;
        border-radius: 5px;
        height: 25px;
        border: 1px solid #D8D8D8;
        width: 300px;
        padding-left: 5px;
    }

    #nameFeedbackGiver,
    #feedbackComments,
    #feedbackRatingExplanationTextArea,
    #extraQuestion {
        border: 1px solid #D8D8D8;
        margin-left: 2px;
    }

    #nameFeedbackGiver:focus,
    #feedbackComments:focus,
    #feedbackRatingExplanationTextArea:focus,
    #extraQuestion:focus
    {
        outline:0px !important;
        -webkit-appearance:none;
        box-shadow: 0 0 2px #00B09A;
        border: 1px solid #00B09A;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    #feedbackExtra{
        overflow-y: scroll;
        width: 50%;
        padding-right: 5px;
    }
    #feedbackComments,
    #feedbackRatingExplanationTextArea{
        height: 100px;
        width: 100%;
        border-radius: 5px;
        text-align: top;
        resize: none;
        font-family:"Nunito", serif;
        padding: 5px 5px;
    }
    #extraQuestion{
        height: 80px;
        width: 100%;
        border-radius: 5px;
        text-align: top;
        resize: none;
        font-family:"Nunito", serif;
        padding: 5px 5px;
    }
    #closeFeedback{
        position: absolute;
        right: 30px;
        background: transparent;
        border-radius: 5px;
        transition: all 0.2s ease-out;
        cursor: pointer;
        font-size: large !important;
        vertical-align: middle;
        padding: 5px;
        line-height: 100% !important;
        outline: none !important;
        border: none !important;
    }
    #closeFeedback:hover{
        background: #e9e9e9;
        border-radius: 100px;
        transition: all 0.2s;
    }
    #closeFeedback:focus,
    #closeFeedback:active{
        outline: none;
    }
    .customAlertBoxFeedback{
        background-color: #f44336;
        opacity: 0;
        display: inline-block;
        color: white;
        width: 350px;
        height: 50px;
        position: absolute;
        z-index: 10000;
        top: 25px;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: 5px;
        text-align: center;
        transition: opacity .5s;
    }
    .customAlertMessageFeedback{
        font-weight: bold;
        line-height: 50px;
    }
    .type_feedback{
        display: flex;
        padding: 0;
    }
    .radioButtonWrapper{
        padding: 0 5px;
        font-size: smaller !important;
        font-family: inherit;
    }

    .modal-bg::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: #1a202c9e;
        z-index: 20;
    }
   `




